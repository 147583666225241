<template>
    <div class="card p-2 card-min-height">
        <div class="table-responsive">
            <table class="table table-width">
                <thead>

                    <tr>
                        <th class="name-th">Project Full Name</th>
                        <th>Project No</th>
                        <th>Project Location </th>
                        <th>Project Manager</th>
                        <th class="client-name-th">Client name</th>
                        <th>Client ID</th>
                        <th>Client Manager</th>

                        <th class="work-order-th">Work Order No</th>
                        <th class="work-order-th">Work order date</th>
                        <th>Project Revenue (Estimated)</th>
                        <th>Project Cost (Estimated)</th>
                        <th>Project Profit (Estimated)</th>

                        <th>Work Completion (%)</th>
                        <th>Invoice Value (Bill Raised)</th>
                        <th>Received Amount</th>
                        <th>Accounts Receivables</th>
                        <th>Project Receivables</th>
                        <th>Actual Income</th>
                        <th>Actual Expense</th>
                        <th>Actual Project Profit (Accural Basis)</th>

                        <th>Actual Receipts</th>
                        <th>Actual Payments</th>
                        <th>Actual Project Profit (Cash Basis)</th>
                        <th>Status</th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in trackerList" :key="i">
                        <td>
                            <router-link :to="getRoute(item.id)" target="_blank">
                                {{ item.project_full_name }}
                            </router-link>
                        </td>
                        <td>{{ item.project_no }}</td>
                        <td>{{ item.project_location }}</td>
                        <td>{{ item.project_manager }}</td>

                        <td>{{ item.client_name }}</td>
                        <td>{{ item.client_id }}</td>
                        <td>{{ item.client_manager }}</td>

                        <td>{{ item.work_order_no }}</td>
                        <td>{{ item.work_order_date }}</td>
                        <td class="amount">{{ formatCurrency(item.estimated_project_revenue) }}</td>
                        <td class="amount">{{ formatCurrency(item.estimated_project_cost) }}</td>
                        <td class="amount">{{ formatCurrency(item.estimated_project_profit) }}</td>

                        <td class="amount">{{ formatCurrency(item.work_completion_percent) }}</td>
                        <td class="amount">{{ formatCurrency(item.invoice_value) }}</td>
                        <td class="amount">{{ formatCurrency(item.receive_amount) }}</td>
                        <td class="amount">{{ formatCurrency(item.accounts_receivable) }}</td>
                        <td class="amount">{{ formatCurrency(item.project_receivables) }}</td>
                        <td class="amount">{{ formatCurrency(item.actual_income) }}</td>
                        <td class="amount">{{ formatCurrency(item.actual_expense) }}</td>
                        <td class="amount">{{ formatCurrency(item.actual_project_profit_accural) }}</td>

                        <td class="amount">{{ formatCurrency(item.actual_reciepts) }}</td>
                        <td class="amount">{{ formatCurrency(item.actual_payments) }}</td>
                        <td class="amount">{{ formatCurrency(item.actual_project_profit_cash) }}</td>

                        <td>
                            <span v-if="item.status" :class="`badge btn-${badgeColor[item.status] ?? 'secondary'}`">
                                {{ item.status }}
                            </span>
                        </td>

                    </tr>

                </tbody>
            </table>
            <p v-if="!trackerList.length" class="text-center mt-5">No data</p>
        </div>

    </div>
</template>

<script setup>

import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import { ref, onMounted } from 'vue'
import { formatCurrency } from "@/services/utils/global";

const $store = useStore();
const route = useRoute();
const router = useRouter();

defineProps(['trackerList'])
const badgeColor = {
    pending: 'warning',
    active: 'success'
}

const getRoute = (projectId) => {
    const params = route.params;
    const query = route.query;
    return {
        name: 'project-register',
        params: {
            companyId: params.companyId,
            moduleId: params.moduleId,
            menuId: params.menuId,
            pageId: params.pageId
        },
        query: {
            projectId: projectId,
            start: query.start,
            end: query.end,
        }
    }
}

</script>


<style scoped>
.work-order-th {
    width: 8%;
}

.client-name-th {
    width: 5%;
}

.name-th {
    width: 6%;
}

.card-min-height {
    min-height: 200px
}

.table-width {
    width: 300% !important;
}

.table> :not(caption)>*>* {
    padding: 0.72rem 0.75rem !important;
}

.table thead th {
    vertical-align: middle !important;
}</style>