<template>
    <div style="min-height: calc(100vh - 158px);" class="card">
        <div style="background: #DBE7F2" class="p-2">
            <TitleButton :showBtn="false" :showAddNew="false" btnTitle="Add New" title="Project Tracker" />

            <div class="row match-height mt-1">
                <TrackerQuerySetter @onClickGo="getProjectTrackerList">

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label for="colFormLabel" class="col-form-label">Client name: </label>
                        <v-select placeholder="Select client" class="w-100" :options="contactProfileList" label="name"
                            :reduce="(name) => name.id" v-model="client_id" />
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label for="colFormLabel" class="col-form-label">Service Group</label>
                        <v-select placeholder="Select service group" v-model="service_project_id" :options="serviceGroups" label="name"
                            :reduce="name => name.id" />
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label for="colFormLabel" class="col-form-label">Business unit</label>
                        <v-select placeholder="Select business unit" class="w-100" :options="businesses" label="name"
                            :reduce="(name) => name.id" v-model="business_id" />
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label for="colFormLabel" class="col-form-label">Status</label>
                        <v-select placeholder="Select status" v-model="selected_status" :options="allStatus" label="name"
                            :reduce="name => name.id" />
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label for="colFormLabel" class="col-form-label">Client Manager: </label>
                        <v-select placeholder="Select client manager" class="w-100" :options="contactList" label="name"
                            :reduce="(name) => name.id" v-model="client_manager_id" />
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label for="colFormLabel" class="col-form-label">Project Team Member: </label>
                        <v-select placeholder="Select team member" class="w-100" :options="contactList" label="name"
                            :reduce="(name) => name.id" v-model="team_member_id" />
                    </div>
                </TrackerQuerySetter>
            </div>
        </div>

        <ListTable :trackerList="trackerList"/>
        <div class="mb-2"></div>

        <div class="px-2 position-absolute bottom-0">
            <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination" />
        </div>
        <Loader v-if="loading" />
    </div>
</template>

<script>
import handleJournal from "@/services/modules/journal"
import handleReport from "@/services/modules/accountingReport";
import { inject, ref } from 'vue'
import { computed } from 'vue'
import { mapMutations } from 'vuex'

import TitleButton from '@/components/atom/TitleButton'
import TrackerQuerySetter from '@/components/molecule/procurement/budget/report/project-tracker/TrackerQuerySetter.vue';

import ListTable from '@/components/molecule/procurement/budget/report/project-tracker/ListTable.vue'
import Pagination from '@/components/atom/Pagination'
import Loader from '@/components/atom/LoaderComponent'
import handleProjects from "@/services/modules/procurement/project";
import handleImportTracker from "@/services/modules/procurement/importTracker";
import handleContact from '@/services/modules/contact'
import handleCBusinesses from "@/services/modules/businesses";

import handleCostCentres from "@/services/modules/ngo/costCentre";

export default {
    name: 'ProjectTracker',

    data: () => ({
        offset: 5,
        trackerList: [],
        contactList: [],
        contactProfileList: [],
        serviceGroups: [
            {
                id: 1,
                name: 'Service group one',
            },
            {
                id: 2,
                name: 'Service group two',
            },
            {
                id: 3,
                name: 'Service group three',
            },
        ],
        allStatus: [
            {
                id: 'pending',
                name: 'pending'
            },
            {
                id: 'active',
                name: 'active'
            },
        ],
        businesses: [],

        client_id: null,
        service_project_id: null,
        business_id: null,
        selected_status: null,
        client_manager_id: null,
        team_member_id: null,
    }),

    components: {
        TitleButton,
        TrackerQuerySetter,
        ListTable,
        Pagination,
        Loader
    },
    computed: {
        companyId() {
            return this.$route.params.companyId
        },
        start() {
            return this.$route.query.start
        },
        end() {
            return this.$route.query.end
        },
        page() {
            return this.$route.query.page
        },
    },
    methods: {
        ...mapMutations({
            setPaginationData: 'setPaginationData',
            resetPagination: 'resetPagination'
        }),

        async getProjectTrackerList() {
            const query = this.getQuery()
            try {
                this.loading = true
                let res = await this.fetchProjectTrackerList(query)
                if (!res.status) {
                    this.showError(res.message)
                }
                if (res.status) {
                    this.trackerList = res.data.data
                    if (res.data.data.length > 0) {
                        this.setPagination(res.data)
                    }
                }
            } catch (err) {
                if (!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if (err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        setPagination(data) {
            this.setPaginationData({
                records: data.meta.total,
                perPage: data.meta.per_page,
                currentPage: data.meta.current_page,
            })
        },

        async onPageChange(page) {
            let routeQuery = Object.assign({}, this.$route.query)
            routeQuery.page = page
            this.$router.push({ path: this.$route.path, query: routeQuery })
            await this.getProjectTrackerList();

        },

        getQuery() {
            let query = '?company_id=' + this.companyId
            if (!this.start && !this.end) return query
            if (this.start) query += '&start_date=' + this.start
            if (this.end) query += '&end_date=' + this.end
            query += '&offset=' + this.offset
            if (this.page) query += '&page=' + this.page
            if (this.client_id) query += "&client_id=" + this.client_id;
            if (this.service_project_id) query += "&service_project_id=" + this.service_project_id;
            if (this.business_id) query += "&business_id=" + this.business_id;
            if (this.selected_status) query += "&selected_status=" + this.selected_status;
            if (this.team_member_id) query += "&team_member_id=" + this.team_member_id;
            if (this.client_manager_id) query += "&client_manager_id=" + this.client_manager_id;

            return query
        },
    },

    setup() {

        const { fetchProjects, fetchProjectTrackerList } = handleProjects()

        const { fetchBusinessList } = handleCBusinesses()

        const { fetchCostCentreList } = handleCostCentres();
        const { fetchContacts } = handleImportTracker();
        const { fetchContactProfiles } = handleContact();

        const { fetchHome } = handleReport();

        const showError = inject('showError');
        const showSuccess = inject('showSuccess');

        const {
            loading,
            fetchAccountHeads
        } = handleJournal()
        return {
            journals: computed(() => store.state.journal.journals),
            fetchCostCentreList,
            fetchContacts,
            fetchContactProfiles,
            fetchHome,
            fetchProjectTrackerList,
            loading,
            showError,
            showSuccess,
            fetchAccountHeads,
            fetchProjects,
            fetchBusinessList
        }
    },

    async mounted() {
        this.loading = true;
        let companyId = this.$route.params.companyId
        let companyQuery = '?company_id=' + companyId;
        
        await this.getProjectTrackerList()
        Promise.all([
            this.fetchContacts(companyQuery).then(res => {
                if (res.data) {
                    this.contactList = res.data;
                }
            }),
            this.fetchContactProfiles(companyQuery).then(res => {
                if (res.data) {
                    this.contactProfileList = res.data;
                }
            }),
            this.fetchBusinessList(companyQuery).then(res => {
                if (res.data) {
                    this.businesses = res.data;
                }
            }),
        ]).then(() => {
            this.loading = false;
        }).catch(err => {
            this.loading = false;
            this.showError(err.message)
        }).finally(() => {
            this.loading = false;
        })
    }
}
</script>
